// переменные проекта

$font-family-base: "Roboto", Helvetica, Arial, sans-serif !default;

$path-fonts:    "../fonts"; // путь к директории со шрифтами

$font-size-base: 16px !default;
$line-height-base: 1.4 !default;

$footer-height: 100px;


$sidebar-width: 264px !default;

// переменные Цвета
// *************************************************************************
$blue: blue;
$green: green;
$orange: #FAAE17;
$red: #EB0017;
$pink: pink;
$cyan: cyan;
$purple: purple;
$yellow: yellow;
$gray: gray;

$gray-50: #FCFCFC;
$gray-100: #F8F8F8;
$gray-200: #F2F2F2;
$gray-300: #EBEBEB;
$gray-400: #E5E5E5;
$gray-500: #DEDEDE;
$gray-600: #A7A7A7;
$gray-700: #6F6F6F;
$gray-800: #383838;
$gray-900: #161616;

$green-50: #E6F6F2;
$green-100: #CCEBE3;
$green-200: #99D7C7;
$green-300: #66C4AB;
$green-400: #33B08F;
$green-500: #009C73;
$green-600: #007556;
$green-700: #004E3A;
$green-800: #00271D;
$green-900: #00100C;


$brand-primary: $green-500;
$brand-secondary: $orange;

// --------------------------------------------------
$body-bg: #F8F8F8 !default;
$text-color: black !default;

$link-color: $brand-primary !default;
$link-hover-color: $brand-secondary !default;

// --------------------------------------------------
$input-border: $gray-300 !default;
$input-bg: $gray-300 !default;
$input-bg-disabled: $gray-800 !default;
$input-color: black !default;
$input-border-focus: $brand-primary !default;
$input-color-placeholder: $gray-600 !default;

@function t($template){
  @return #{'[data-template="#{$template}"]'};
}

$template-main: t("index");
$template-login: t("login");
