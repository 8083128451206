// Variables grid

// Breakpoints
//
// Определите минимальные размеры, при которых ваш макет будет меняться
$grid-breakpoints: (
  xs: 0,
  sm: 600px,
  md: 960px,
  lg: 1280px,
  xl: 1920px
) !default;

//adptive margins
$levels: (
  //        0     600   960   1280  1920
  blue   : (5px,  10px, 20px,  40px,  80px),  //2XS
  red    : (8px,  16px, 16px, 16px, 16px), //XS
  orange : (12px, 24px, 24px, 24px, 24px), //S
  yellow : (16px, 32px, 32px, 32px, 33px), //M
  green  : (20px, 40px, 40px, 40px, 48px), //L
  cyan   : (28px, 48px, 48px, 48px, 64px), //XL
  purple : (36px, 72px, 72px, 72px, 96px), //2XL
  pink   : (56px, 96px, 96px, 96px, 120px),//3XL
);

$font-sizes: (
  //    0     600   960   1280  1920  line-height
  h1:  (30px, 36px, 48px, 48px, 60px, (33px, 45px, 48px, 48px, 60px)),
  h2:  (28px, 32px, 40px, 40px, 48px, (33px, 45px, 48px, 48px, 60px)),
  h3:  (30px, 36px, 48px, 48px, 60px, (33px, 45px, 48px, 48px, 60px)),
  h6:  (20px, 30px, 40px, 50px, 60px, 1.25),
  nav: (16px, 20px, 20px, 24px, 24px, 1.2),
  p1:  (30px, 36px, 48px, 48px, 60px, 1.5),
  p2:  (30px, 36px, 48px, 48px, 60px, 1.5),
);


@include _assert-ascending($grid-breakpoints, "$grid-breakpoints");
@include _assert-starts-at-zero($grid-breakpoints);

// Grid containers
//
// Определите максимальную ширину контейнера для разных размеров экрана
$container-max-widths: (
  sm: 984px,
  md: 984px,
  lg: 984px,
  xl: 984px
) !default;

@include _assert-ascending($container-max-widths, "$container-max-widths");


// Columns
//
// Установите количество ваших столбцов и укажите отступ между колонками
$grid-columns:             12 !default;
$gutter-width:           	 24px !default;

// Masonry
$masonry-columns-count-sm: 1 !default;
$masonry-columns-count-md: 2 !default;
$masonry-columns-count-lg: 3 !default;
$masonry-columns-count-xl: 4 !default;


// Breakpoints mixins example
//
// Пример как использовать точки адаптации

// @include breakpoint-up(xs) { ... }
// @include breakpoint-up(sm) { ... }
// @include breakpoint-up(md) { ... }
// @include breakpoint-up(lg) { ... }
// @include breakpoint-up(xl) { ... }

// @include breakpoint-down(xs) { ... }
// @include breakpoint-down(sm) { ... }
// @include breakpoint-down(md) { ... }
// @include breakpoint-down(lg) { ... }
// @include breakpoint-down(xl) { ... }

// @include breakpoint-range(xs) { ... }
// @include breakpoint-range(sm) { ... }
// @include breakpoint-range(md) { ... }
// @include breakpoint-range(lg) { ... }
// @include breakpoint-range(xl) { ... }
