$btn-font-size: $font-size-base !default;
$btn-line-height: $line-height-base !default;
$btn-box-shadow: inset 0 3px 5px rgba(black,.125);
$btn-disabled-opacity: .65;
$btn-border-width: 1px !default;
// *************************************************************************

.btn {
	display: inline-block;
	margin-bottom: 0;
	font-weight: normal;
	text-align: center;
	vertical-align: middle;
	touch-action: manipulation;
	cursor: pointer;
	background-image: none;
	border: $btn-border-width solid transparent;
	white-space: nowrap;
	font-size: $btn-font-size;
	line-height: $btn-line-height;
	user-select: none;
	transition-property: box-shadow, background-color;
	transition-duration: .2s;
	outline: none !important;
	text-decoration: none !important;
	padding: 6px 12px;

	&:active,
	&.active {
		box-shadow: $btn-box-shadow;
	}

	&.disabled,
	&[disabled],
	fieldset[disabled] & {
		cursor: not-allowed;
		opacity: $btn-disabled-opacity;
		box-shadow: none;
	}
  &.btn-hollow {
    border-radius: 10px;
    border: 1px solid $green-500;
    padding: 8px 16px;
    color: $green-500;
  }
  &.btn-primary {
    font-weight: 500;
    padding: 12px 24px;
    color: #fff;
    border-radius: 10px;
    background: $brand-primary;
  }
  &.btn-medium {
    padding: 9px 16px;
  }
  &.btn-gray {
    border-radius: 10px;
    background: $gray-500;
    padding: 16px 24px;
    color: $gray-600;
    font-weight: 500;
  }
}
.btn-back-icon {
  display: flex;
  align-items: center;
  color: #000;
  span {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: $gray-300;
    color: $gray-700;
    margin-right: 24px;
    border-radius: 50%;
  }
}
a.btn {
	&.disabled,
	fieldset[disabled] & {
		pointer-events: none;
	}
}

.btn-block {
	display: block;
	width: 100%;
}

input[type="submit"],
input[type="reset"],
input[type="button"] {
	&.btn-block {
		width: 100%;
	}
}
