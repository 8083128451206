table {
	border-collapse: collapse;
}
.ui-tables {
  @include breakpoint-down(sm) {
    min-width: 100%;
    max-width: 800px;
  }
  &-center {
    text-align: center;
  }
  &-tr {
    @include create-row();
    align-items: center;
    &:not(:last-child) {
      margin-bottom: 8px;
    }
  }
  &-td {
    @include col-custom(3);
    @include breakpoint-down(sm) {
      @include col-custom(4);
    }
  }
  &-head {
    margin-bottom: 16px;
    color: $gray-700;
    @include text-small;
    .ui-tables-tr {
      padding: 0 32px;
      @include breakpoint-down(sm) {
        padding: 0 8px;
      }
    }
  }
  &-body {
    .ui-tables-tr {
      padding: 16px 32px;
      background: #FFFFFF;
      border-radius: 10px;
      @include breakpoint-down(sm) {
        padding: 0 8px;
      }
    }
    .ui-tables-td {
      @include breakpoint-down(sm) {
        padding-top: 10px;
        padding-bottom: 10px;
        &:nth-child(4) {
          width: 100%;
        }
      }
    }
  }
}
.schedule-control {
  display: flex;
  align-items: center;
  justify-content: space-between;
  // @include breakpoint-down(sm) {
  //   padding: 0 8px;
  // }
  &-right {
    text-align: right;
    width: 100%;
    padding: 9px 16px;
    color: $brand-primary;
    border: 1px solid transparent;
    border-radius: 10px;
  }
  &-left {
    color: $red;
    & + .schedule-control-right {
      width: auto;
      border-color: $brand-primary;
    }
  }
}
