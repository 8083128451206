.footer {
  border-top: 1px solid $gray-300;
  padding: 12px 0;
  // @include breakpoint-up(md) {
  //   display: none;
  // }
  // &-wrap {
  // }
  .row {
    align-items: center;
  }
  .btn {
    @include breakpoint-down(md) {
      margin-bottom: 16px;
    }
  }
}
.hide-desktop {
  @include breakpoint-up(md) {
    display: none;
  }
}
.content-footer {
  &-title {
    margin-right: 16px;
    @include text-small;
    @include breakpoint-down(sm) {
      margin-right: 0px;
      margin-bottom: 8px;
    }
  }
  &-item {
    // margin-bottom: 16px;
    display: flex;
    align-items: center;
    @include breakpoint-down(sm) {
      display: block;
      margin-bottom: 24px;
    }
  }
  &-phone {
    display: flex;
    align-items: center;
    color: #000;
    .icon {
      font-size: 24px;
      margin-right: 8px;
      color: #00805F;
    }
  }
}

.copyright {
  @include text-small;
  color: $gray-700;
  white-space: nowrap;
}
.social-list {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
	@include list-unstyled;
  margin: 0 -4px;
  li {
    padding: 0 4px;
    font-size: 24px;
  }
  a {
    color: $gray-600;
  }
}
