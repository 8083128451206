.sidebar {
  width: $sidebar-width;
  background: #fff;
  flex: 1 0 auto;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  z-index: 10;
  transition: transform .4s ease;
  height: 100%;
  @include breakpoint-down(sm) {
    display: none;
    top: 56px;
    width: 100%;
    max-width: 450px;
    background: $body-bg;
    height: auto;
    bottom: initial;
    min-height: calc(100% - 56px);
    &.is--opened {
      display: flex;
    }
  }
  &--wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
    @include breakpoint-down(sm) {
      width: 100%;
      height: auto;
    }
  }
  &-header {
    padding-top: 40px;
    padding-left: 40px;
    margin-bottom: 56px;
    @include breakpoint-down(sm) {
      border-top: 1px solid $gray-300;
      border-bottom: 1px solid $gray-300;
      padding: 12px 16px;
      margin-bottom: 40px;
    }
  }
  &-body {
    padding-left: 40px;
    flex: 1 0 auto;
    @include breakpoint-down(sm) {
      padding: 0 16px;
    }
    li {
      display: block;
      &:not(:last-child) {
        margin-bottom: 34px;
      }
    }
    a {
      display: flex;
      position: relative;
      align-items: center;
      color: #000;
      .item-img {
        position: relative;
        margin-right: 8px;
      }
      img {
        &:nth-child(2) {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%,-50%);
          opacity: 0;
        }
      }
    }
    .active {
      a {
        color: $brand-primary;
      }
      img {
        &:nth-child(1) {
          opacity: 0;
        }
        &:nth-child(2) {
          opacity: 1;
        }
      }
    }
  }
  &-footer {
    // border-top: 1px solid $gray-300;
    padding: 24px 0 32px 40px;
    @include breakpoint-down(sm) {
      border-top: none;
      padding: 32px 16px;
    }
  }
  .logo,
  .content-footer-item {
    @include breakpoint-down(sm) {
      display: none;
    }
  }
  .log-out {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: $gray-600;
    width: 100%;
    .icon {
      font-size: 30px;
      color: $gray-700;
    }
  }
  .nav-main,
  .log-out {
    @include breakpoint-up(md) {
      display: none;
    }
  }
}
