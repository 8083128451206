.item-appeal {
  @include breakpoint-down(sm) {
    &:not(:last-child) {
      margin-bottom: 16px;
    }
  }
  &-wrap {
    background: #FFFFFF;
    border-radius: 10px;
    color: #000;
    padding: 32px;
    min-height: 184px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .item-media {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: $gray-100;
  }
  .item-title {
    @include title-3;
  }
}
.appeal-notifications-list {
  .item-list {
    border: 1px solid transparent;
    background: #FFFFFF;
    border-radius: 10px;
    padding: 24px 32px;
    position: relative;
    @include breakpoint-down(sm) {
     padding: 24px;
    }
    &-primary {
      background: $green-50;
      a {
        color: $green-600;
      }
    }
    &:not(:last-child) {
      margin-bottom: 8px;
    }
  }
  .item-date {
    color: $gray-700;
    margin-bottom: 4px;
  }
  .item-title {
    @include title-3;
    margin-bottom: 16px;
  }
  a {
    color: $green-400;
  }
  .item-badge {
    padding: 5px 12px;
    border-radius: 4px;
    background: $gray-200;
    position: absolute;
    top: 19px;
    right: 32px;
    @include breakpoint-down(sm) {
     right: 24px;
     @include text-small;
    }
    &-secondary {
      background: $brand-secondary;
    }
  }
}
.appeal-notifications-inner {
  max-width: 714px;
  .item-date {
    color: $gray-700;
    margin-bottom: 16px;
  }
  .item-title {
    @include title-2;
    margin-bottom: 16px;
  }
}
.item-history-inner {
  max-width: 714px;
  padding: 24px 32px 30px 32px;
  border-radius: 10px;
  &:nth-child(odd) {
    background: $green-50;
    margin-left: auto;
  }
  &:nth-child(even) {
    background: $gray-300;
  }
  &:not(:last-child) {
    margin-bottom: 16px;
  }
  .item-date {
    color: $gray-700;
    margin-bottom: 8px;
  }
  .item-meta {
    color: $gray-700;
    margin-top: 16px;
  }
  .item-doc-list {
    margin-top: 24px;
    display: flex;
    flex-wrap: wrap;
    .item-list {
      display: flex;
      align-items: center;
      color: #000;
      max-width: 282px;
      width: 100%;
      &:not(:last-child) {
        margin-right: 24px;
      }
    }
    img {
      margin-right: 8px;
      width: 30px;
      height: 30px;
    }
  }
}
.appeal-history-inner-add {
  position: fixed;
  bottom: 16px;
  right: 16px;
  z-index: 10;
  display: inline-flex;
  align-items: center;
  border-radius: 10px;
  background: $brand-primary;
  color: #fff;
  font-weight: 500;
  padding: 16px;
  .icon {
    font-size: 26px;
    margin-right: 10px;
  }
}
