.title-1 {
  @include font-size('h1');
}
.title-2 {
  @include font-size('h6');
}

.section-default {
  @include spacing-level(cyan, top, padding);
  @include spacing-level(cyan, bottom, padding);
  .section__title {
    @include spacing-level(yellow, bottom);
  }
  &--bg-gray {
    background: #f8f8f8;;
  }
}
