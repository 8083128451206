.news {
  .item-news {
    @include col-custom(4);
    @include breakpoint-down(sm) {
      width: 100%;
    }
  }
  &-list {
    margin-bottom: -24px;
  }
  &-inner {
    &-content {
      h1, h2, h3, h4, h5, h6 {
        margin-bottom: 32px;
      }
      img {
        margin-bottom: 32px;
        border-radius: 10px;
      }
      p {
        margin-bottom: 24px;
        b {
          font-weight: 500;
        }
      }
    }
    &-date {
      margin-bottom: 16px;
      color: $gray-700;
    }
  }
}
.item-news {
  margin-bottom: 24px;
  &-wrap {
    display: flex;
    min-height: 260px;
    flex-direction: column;
    justify-content: space-between;
    padding: 24px 32px;
    background: #FFFFFF;
    border-radius: 10px;
    color: #000;
    &:hover {
      color: #000;
      background: $green-50;
      .item-icon {
        color: $green-500;
      }
    }
  }
  .item-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .item-desc {
    margin-bottom: 16px;
  }
  .item-date {
    @include text-small;
    color: $gray-700;
  }
  .item-icon {
    color: $gray-600;
    font-size: 14px;
  }
}
