.ui {
  &-head {
    margin-bottom: 48px;
    &-m-b-24 {
      margin-bottom: 24px;
    }
  }
  &-desc {
    @include text-small;
    color: $gray-700;
    margin-top: 8px;
  }
  &-segment {
    &:not(:last-child) {
      margin-bottom: 32px;
    }
    &-wrap {
      background: #FFFFFF;
      border-radius: 10px;
      position: relative;
      padding: 32px;
      @include breakpoint-down(sm) {
        padding: 32px 24px 24px;
      }
    }
    .btn-group {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      margin-top: 11px;
      .btn {
        margin-right: 16px;
        @include breakpoint-down(sm) {
          width: 100%;
          margin-right: 0;
          &:not(:last-child) {
            margin-bottom: 16px;
          }
        }
      }
    }
    .ui-banner {
      margin-bottom: 32px;
    }
  }
  &-promo-media {
    margin-bottom: 32px;
    img {
      border-radius: 10px;
    }
  }
  &-promo-carousel {
    position: relative;
    margin-bottom: 40px;
    .item-slide {
      padding-bottom: ratio(300px, 960px);
      @include breakpoint-down(sm) {
        padding-bottom: ratio(600px, 960px);
      }
    }
    .img {
      @include stretch;
      background-size: cover;
      background-position: center;
      z-index: -1;
      border-radius: 10px;
      &::before {
        content: '';
        background: rgba($color: #000000, $alpha: .3);
        @include stretch;
        border-radius: 10px;
      }
    }
    .item-title {
      max-width: 468px;
      color: #fff;
      position: absolute;
      z-index: 3;
      top: 48px;
      left: 82px;
      right: 82px;
      text-align: left;
      @include breakpoint-down(sm) {
        top: 24px;
        left: 24px;
        right: 24px;
      }
    }
    .section-carousel-controls {
      position: absolute;
      left: 82px;
      z-index: 2;
      bottom: 32px;
      @include breakpoint-down(sm) {
        bottom: 24px;
        left: 24px;
      }
    }
  }
  &-banner-wrap {
    display: flex;
    justify-content: space-between;
    padding: 24px 79px 24px 40px;
    background: #EBEBEB;
    border-radius: 10px;
    @include breakpoint-down(sm) {
      padding: 24px;
    }
  }
  &-banner {
    &-media {
      max-width: 206px;
      margin: -40px 0;
      @include breakpoint-down(sm) {
        display: none;
      }
      img {
        @extend .img-fluid;
        border-radius: 50%;
      }
    }
    &-link {
      margin-top: 16px;
    }
    &-title {
      margin-bottom: 4px;
    }
    &-desc {
      color: $gray-700;
      @include text-small;
    }
  }
}
.filter-btn {
  border-radius: 10px;
  border: 1px solid $gray-400;
  padding: 5px 16px;
  display: inline-flex;
  align-items: center;
  margin-bottom: 18px;
  .icon {
    font-size: 30px;
    margin-right: 8px;
    color: $gray-700;
  }
}
