@mixin title-1 {
  font-weight: 500;
  font-size: 32px;
  line-height: 38px;
  letter-spacing: -0.3px;
}
@mixin title-2 {
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: -0.3px;
}
@mixin title-3 {
  font-size: 20px;
  line-height: 28px;
  letter-spacing: -0.3px;
}

// @mixin text-large {

// }

@mixin text-normal {
  font-size: 16px;
  line-height: 22px;
}

@mixin text-small {
  font-size: 14px;
  line-height: 20px;
}
