.header {
  border-bottom: 1px solid $gray-300;
  @include breakpoint-down(sm) {
    background: #fff;
    border-bottom: none;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 20;
    &.sidebar--opened {
      background: $body-bg;
    }
  }
  &-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 13px 0 12px 0;
    @include breakpoint-down(sm) {
      padding: 8px 0;
    }
  }
  .log-out {
    display: flex;
    align-items: center;
    @include text-small;
    color: $gray-700;
    .icon {
      margin-left: 16px;
      font-size: 30px;
    }
  }
  .logo {
    @include breakpoint-up(md) {
      display: none;
    }
    img {
      height: 40px;
    }
  }
  .nav-main,
  .log-out   {
    @include breakpoint-down(sm) {
      display: none;
    }
  }
}
