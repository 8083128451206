.knowledge-base-accordion {
  .item-accordion {
    padding: 16px 32px;
    background: #FFFFFF;
    border-radius: 10px;
    &:not(:last-child) {
      margin-bottom: 16px;
    }
  }
  .item-title {
    @include title-3;
    justify-content: space-between;
    align-items: center;
    &-icon {
      min-width: 40px;
      font-size: 30px;
    }
  }
  .item-dropdown {
    padding-top: 24px;
    @include text-small;
  }
}
.category-sidebar {
  padding: 16px;
  background: #FFFFFF;
  border-radius: 10px;
  .item-accordion {
    padding: 8px 0;
    &:not(:last-child) {
      margin-bottom: 8px;
    }
    .item-title {
      justify-content: space-between;
      align-items: center;
      padding-left: 34px;
      padding-right: 40px;
      &-icon {
        position: absolute;
        font-size: 30px;
        top: 50%;
        right: 0;
        transform: translate(-50%,-50%) rotate(180deg) !important;
      }
    }
    .item-title-media {
      position: absolute;
      top: 0;
      left: 0;
      max-width: 24px;
    }
    &.toggled {
      .item-title-icon {
        transform: translate(-50%,-50%) rotate(0deg) !important;
      }
    }
    .item-dropdown {
      padding-top: 13px;
      @include text-small;
      color: $gray-700;
    }
    ul {
      @include list-unstyled;
      margin-bottom: 0;
      padding-left: 34px;
      li {
        &:not(:last-child) {
          margin-bottom: 4px;
        }
      }
      a {
        color: inherit;
      }
    }
  }
}
.knowledge-base {
  &-right {
    @include breakpoint-down(sm) {
      order: -1;
      margin-bottom: 24px;
    }
  }
}
