.my-col-5-3 {
  @include col-custom(4.1);
}
.my-col-3-9 {
  @include offset-custom(4);
  @include col-custom(5.6);
}
.my-col-2-8 {
  @include col-custom(2.8);
}
.div {
  @include spacing-level(blue, both, padding);
  @include font-size('h1');
}
[class^="page page--"] {
  padding: 32px 0;
  @include breakpoint-down(sm) {
    padding: 80px 0 32px;
  }
}
.page--login {
  padding: 0;
}

[x-cloak] { display: none; }
