.swiper-pagination {
  position: static;
  display: flex;
  align-items: center;
  justify-content: center;
  &-bullet {
    width: 8px;
    height: 8px;
    background: $gray-400;
    border-radius: 50%;
    &:not(:last-child) {
      margin-right: 8px;
    }
    &-active {
      background: $brand-primary;
    }
  }
  &-white {
    .swiper-pagination-bullet {
      background: rgba($color: #fff, $alpha: .4);
      &-active {
        background: #fff;
      }
    }
  }
}
