.item-contacts {
  margin-bottom: 24px;
  &-wrap {
    background: #fff;
    border-radius: 10px;
    padding: 24px 32px;
    height: 100%;
  }
  .item-list {
    @include text-small;
    &:not(:last-child) {
      margin-bottom: 8px;
    }
  }
  .item-title {
    margin-bottom: 4px;
    color: $gray-600;
    &-main {
      @include title-3;
      margin-bottom: 16px;
    }
  }
}
