.tabs {
  .tabs-header {
    button {
      @include text-small;
      padding: 10px 16px;
      border-radius: 10px;
      &.isActive {
        background: $brand-secondary;
      }
    }
  }
  .tabs-body {
    border: 1px solid #f7f7f7;
    border-radius: 5px;
  }
}
