.about {
  &-desc {
    margin-bottom: 40px;
  }
  &-title {
    @include title-3;
    margin-bottom: 24px;
  }
  &-list {
    margin-bottom: -24px;
  }
}
.item-about {
  margin-bottom: 24px;
  &-wrap {
    background: #FFFFFF;
    color: #000;
    border-radius: 10px;
    padding: 24px 32px;
    display: flex;
    height: 100%;
  }
  .item-media {
    min-width: 30px;
    margin-right: 16px;
    text-align: center;
    max-width: 30px;
    img {
      @extend .img-fluid;
    }
  }
  .icon {
    font-size: 30px;
    color: $brand-primary;
  }
}
.item-download {
  margin-bottom: 24px;
  &-wrap {
    display: block;
    color: #000;
  }
  .item-media {
    position: relative;
    padding-bottom: ratio(314px, 222px);
    margin-bottom: 16px;
  }
  .img {
    @include stretch;
    background-size: cover;
    background-position: center;
    border-radius: 10px;
  }
  .item-head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 8px;
  }
  .item-icon {
    color: $gray-700;
    font-size: 30px;
  }
  .item-date {
    @include text-small;
    color: $gray-700;
  }
}
