.item-doc {
  margin-bottom: 13px;
  &-wrap {
    position: relative;
    padding-left: 38px;
    display: block;
    color: #000;
  }
  .item-img {
    position: absolute;
    top: 0;
    left: 0;
  }
  &-content {
    padding-top: 4px;
  }
  .item-desc {
    margin-bottom: 4px;
    @include text-small;
    color: $gray-600;
  }
  .item-title {
    h3 {
      font-weight: normal;
    }
  }
  .item-date {
    @include text-small;
    color: $brand-primary;
    margin-top: 16px;
  }
}
.insurance-doc {
  &-title {
    font-weight: 500;
    color: $brand-primary;
    margin-bottom: 16px;
    &-secondary {
      color: $brand-secondary;
    }
  }
  &-item {
    margin-bottom: 16px;
  }
  .item-list {
    margin-bottom: 8px;
  }
  .item-list-wrap {
    border-radius: 10px;
    background: $gray-200;
    padding: 24px 16px;
    display: flex;
    color: #000;
  }
  .item-media {
    margin-right: 8px;
    max-width: 30px;
    img {
      @extend .img-fluid;
    }
  }
  .item-content {
    padding-top: 4px;
  }
  .item-title {
    margin-bottom: 4px;
  }
  .item-desc {
    @include text-small;
    color: $gray-700;
  }
}
