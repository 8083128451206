.nav-main {
  ul {
    margin-bottom: 0;
  }
  a {
    color: #000;
    @include text-small;
  }
  li {
    @include breakpoint-down(sm) {
      display: block;
    }
    &:not(:last-child) {
      margin-right: 32px;
      @include breakpoint-down(sm) {
        margin-right: 0;
        margin-bottom: 16px;
      }
    }
  }
}
