.tab-pane {
	display: none;
	&.active {
		display: block;
	}
}
.ui-tabs {
	@include list-unstyled;
  display: flex;
  flex-wrap: wrap;
  margin: 0 -8px 16px;
  li {
    padding: 0 8px;
    margin-bottom: 8px;
  }
  a {
    color: #000;
    @include text-small;
    padding: 10px 16px;
    border-radius: 10px;
    display: block;
  }
  .active {
    a {
      background: $brand-secondary;
    }
  }
}
