
.segment {
  &-status {
    position: absolute;
    left: 32px;
    top: 0;
    transform: translateY(-50%);
    border-radius: 4px;
    background: $green-500;
    padding: 6px 10px;
    @include text-small;
    color: #fff;
    &-secondary {
      background: $brand-secondary;
    }
  }
  &-title {
    margin-bottom: 4px;
    &-primary {
      color: $brand-primary;
      margin-bottom: 16px;
    }
  }
  &-desc {
    color: $gray-700;
    margin-bottom: 16px;
  }
  &-list {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -28px 8px;
    @include breakpoint-down(sm) {
      margin: 0 -16px 8px;
    }
    .item-list {
      padding: 0 28px;
      margin-bottom: 16px;
      @include breakpoint-down(sm) {
        padding: 0 16px;
      }
    }
    .item-desc {
      @include text-small;
      color: $gray-600;
      margin-bottom: 4px;
    }
    .item-title {
      @include title-3;
    }
  }
}
