.login {
  background: linear-gradient(to right, $gray-200 50%, #fff 50%);
  @include breakpoint-down(md) {
    background: $gray-200;
  }
  .container {
    max-width: 1040px;
  }
  &-wrap {
    display: flex;
  }
  &-left,
  &-right {
    width: 50%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    @include breakpoint-down(md) {
      width: 100%;
    }
    &-body {
      flex: 1 0 auto;
      display: flex;
      align-items: center;
    }
  }
  &-left {
    text-align: center;
    &-head {
      padding-top: 16px;
    }
    &-head,
    &-body {
      max-width: 392px;
    }
    &-body {
      @include breakpoint-down(md) {
        padding-top: 16px;
      }
    }
  }
  &-right {
    @include breakpoint-down(md) {
      display: none;
    }
  }
  .title {
    margin-bottom: 16px;
    h3 {
      font-weight: normal;
    }
  }
  .desc {
    margin-bottom: 32px;
  }
  .tabs-header {
    margin-bottom: 24px;
  }
}
.form-login {
  .form-control {
    background: #FFFFFF;
    text-align: center;
    &::placeholder {
      text-align: center;
    }
    &-phone {
      @extend .form-control;
      background: transparent;
    }
  }
  .form-group-footer {
    margin-top: 38px;
  }
}
.login-carousel {
  width: 100%;
  .swiper-container {
    margin-bottom: 64px;
    max-width: 392px;
    margin-right: 0;
  }
  .section-carousel-controls {
    margin-left: auto;
    max-width: 392px;
  }
}
.item-slide {
  text-align: center;
  .item-media {
    margin: 0 auto 40px;
    max-width: 364px;
    img {
      border-radius: 50%;
    }
  }
  .item-title {
    margin-bottom: 16px;
  }
  .item-desc {
    color: $gray-700;
  }
}
