.item-accordion {
  &:not(.toggled) {
    .item-dropdown {
      display: none;
    }
    .item-title {
      color: #000;
      &:hover {
        color: inherit;
      }
    }
    .item-title-icon {
      transform: rotate(180deg);
    }
  }
  .item-title {
    display: flex;
    position: relative;
    cursor: pointer;
    &:hover {
      color: $brand-primary;
    }
  }
  p {
    &:last-child {
      margin-bottom: 0px;
    }
  }
}
